.logo-home {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 100;
    text-decoration: none;
    color: var(--font-color);
    display: flex;
    align-items: center;
    gap: 10px;
    transition: color 0.2s;
}

.logo-home:hover {
    color: var(--hl-color);
}

.logo-text {
    font-size: 1.2rem;
    font-weight: bold;
}

.shortcut-list-page {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 0px;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
}

.shortcut-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    padding: 10px;
    position: sticky;
    top: 0;
    background: var(--bg-color);
    z-index: 10;
    border-bottom: 1px solid var(--hl-color);
}

.list-selector {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    /* Changed from 200px to 120px */
    gap: 10px;
    /* Added gap for better spacing */
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    /* Added horizontal padding */
}

.list-selector-item {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-button {
    background: none;
    border: none;
    color: var(--font-color);
    cursor: pointer;
    padding: 10px;
    transition: all 0.2s;
    font-size: 1em;

    min-width: 100px;
    max-width: 100px;
    margin: 0 auto;

}

.list-button:hover {
    color: var(--hl-color);
}

.list-button.selected {
    color: var(--hl-color);
    border-bottom: 2px solid var(--hl-color);
}

.search-bar {
    flex: 1;
    max-width: 600px;
    min-width: 200px;
    padding: 12px 20px;
    font-size: 1.1rem;
    border: none;
    border-radius: 8px;
    background: color-mix(in srgb, var(--bg-color) 70%, var(--fg-color));
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    color: var(--font-color);
    transition: all 0.2s;
}

.search-bar:focus {
    outline: none;
    background: var(--fg-color);
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1),
        0 0 0 1px var(--hl-color);
}

.search-bar::placeholder {
    color: var(--font-color);
    opacity: 0.6;
}

.shortcuts-grid {
    flex: 1;
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
    padding-bottom: 0px;

}

.shortcut-card {
    background: var(--fg-color);
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
}

.info-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--font-color);
    opacity: 0.6;
    cursor: help;
    transition: all 0.2s;
}

.info-icon:hover {
    color: var(--hl-color);
    opacity: 1;
}

.info-tooltip {
    position: absolute;
    top: -40px;
    right: 0;
    background: var(--fg-color);
    padding: 8px 12px;
    border-radius: 6px;
    border: 1px solid var(--hl-color);
    font-size: 0.8rem;
    color: var(--font-color);
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;
    z-index: 10;
    white-space: nowrap;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.info-icon:hover+.info-tooltip {
    opacity: 1;
    visibility: visible;
    top: -50px;
}

.shortcut-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 5px 15px color-mix(in srgb, var(--bg-color) 70%, black);
}

.shortcut-key {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
}


.keycap {
    background-color: var(--bg-color);
    border: 1px solid var(--hl-color);
    border-radius: 6px;
    padding: 4px 8px;
    font-family: monospace;
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--font-color);
    box-shadow:
        0 2px 0 var(--hl-color),
        0 3px 3px rgba(0, 0, 0, 0.2);
    min-width: 20px;
    text-align: center;
}

.operator {
    color: var(--font-color);
    opacity: 0.7;
    padding: 0 4px;
}

.shortcut-command {
    color: var(--font-color);
    font-weight: 600;
    font-size: 1.5rem;
    opacity: 0.8;
}

.shortcuts-grid::-webkit-scrollbar-track {
    background: var(--bg-color);
}

.shortcuts-grid::-webkit-scrollbar-thumb {
    background: var(--hl-color);
    border-radius: 2px;
}

/* Media queries based on History.css */
@media screen and (max-width: 1200px) {
    .shortcuts-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}


@media screen and (max-width: 992px) {
    .list-selector {
        grid-template-columns: repeat(4, 1fr);
        padding: 10px 50px;

    }
}

@media screen and (max-width: 768px) {
    .list-selector {
        grid-template-columns: repeat(2, 1fr);
        padding: 10px 40px;

    }

    .shortcuts-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
        padding: 8px;
    }

    .shortcut-header {
        flex-direction: column;
        gap: 20px;
    }

    .search-bar {
        width: 100%;
    }
}

@media screen and (max-width: 576px) {
    .list-selector {
        grid-template-columns: repeat(2, 1fr);
    }

    .shortcuts-grid {
        grid-template-columns: 1fr;
        gap: 5px;
        padding: 5px;
    }
}

@media screen and (max-width: 380px) {
    .list-selector {
        grid-template-columns: 1fr;
    }
}

/* Add these new styles */
.view-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 20px auto;
    width: 100%;
    max-width: 800px;
    padding: 0 20px;
}

.view-toggle {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: var(--font-color);
    opacity: 0.6;
    border-radius: 4px;
}

.view-toggle.active {
    opacity: 1;
    background: var(--fg-color);
}

.shortcuts-container {
    flex: 1;
    max-height: 70vh;
    overflow-y: auto;
}

.shortcuts-container.list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 70vh;
    overflow-y: auto;
    padding: 0 20px;
}

.shortcut-list-item {
    display: grid;
    grid-template-columns: 1fr 2fr auto;
    align-items: center;
    gap: 20px;
    padding: 12px 16px;
    background: var(--fg-color);
    border-radius: 6px;
    transition: all 0.2s;
}

.shortcut-list-item:hover {
    background: color-mix(in srgb, var(--fg-color) 98%, var(--hl-color));
}

.shortcut-list-item .shortcut-key {
    font-family: var(--font-mono);
    color: var(--hl-color);
}

.shortcut-list-item .info-icon {
    cursor: help;
    opacity: 0.6;
}

.shortcut-list-item .info-icon:hover {
    opacity: 1;
}

/* Update existing shortcuts-container for grid view */
.shortcuts-container.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
    padding-bottom: 100px;
}

/* Optional: Add custom scrollbar styling */
.shortcuts-container::-webkit-scrollbar {
    width: 8px;
}

.shortcuts-container::-webkit-scrollbar-track {
    background: var(--bg-color);
}

.shortcuts-container::-webkit-scrollbar-thumb {
    background: var(--hl-color);
    border-radius: 4px;
}

/* Update media queries */
@media screen and (max-width: 768px) {
    .view-controls {
        padding: 0 10px;
    }

    .search-bar {
        font-size: 1rem;
        padding: 10px 16px;
    }
}

@media screen and (max-width: 480px) {
    .view-controls {
        flex-wrap: wrap;
        gap: 10px;
    }

    .search-bar {
        order: -1;
        width: 100%;
        min-width: 0;
    }
}

.top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    width: 100%;
    position: relative;
}

.search-section {
    padding-top: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: 600px;
    width: 40%;
}

.search-bar {
    flex: 1;
    padding: 8px 16px;
    font-size: 0.95rem;
    border: none;
    border-radius: 6px;
    background: color-mix(in srgb, var(--bg-color) 70%, var(--fg-color));
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    color: var(--font-color);
    transition: all 0.2s;
}

.view-toggle {
    padding: 6px;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--font-color);
    opacity: 0.6;
    border-radius: 4px;
}

.view-toggle.active {
    opacity: 1;
    background: var(--fg-color);
}

@media screen and (max-width: 768px) {
    .top-bar {
        flex-direction: column;
        gap: 16px;
        padding: 16px 12px;
    }

    .search-section {
        position: static;
        transform: none;
        width: 100%;
        margin: 10px 0;
    }
}

/* Update CommandPalette and logo-home positioning */
.command-palette-wrapper {
    z-index: 1;
}

.logo-home {
    z-index: 1;
}

@media screen and (max-width: 1200px) {
    .search-section {
        width: 30%;
    }
}