.footer {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
  background-color: var(--bg-color);
  color: var(--hl-color);
  border-top: 1px solid var(--hl-color);
  max-width: 1000px;
  width: 90%;
  gap: 20px;
}

.footer-item {
  flex: 1;
  text-align: center;
  color: var(--hl-color);
}

.footer-item:first-child {
  text-align: left;
}

.footer-item:last-child {
  text-align: right;
}

.footer a {
  color: var(--hl-color);
}

.footer a:hover {
  text-decoration: underline;
}

.x-handle {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.x-handle span {
  font-weight: bold;
}

.buy-me-a-coffee {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

/* Add icon size control */
.footer-item svg,
.footer-item span {
  font-size: 0.9em;
  width: 16px;
  height: 16px;
}

/* If you want to specifically target just the Coffee icon */
.buy-me-a-coffee svg {
  width: 14px;
  height: 14px;
}