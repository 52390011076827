.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: var(--bg-color);
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
  }
  .modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    color: var(--font-color);
  }
  
  .modal-close-button {
    background: none;
    border: none;
    color: var(--font-color);
    cursor: pointer;
  }
  
  .modal-close-button:hover {
    color: var(--hl-color);
  }