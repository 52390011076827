.Hotkey-container {
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    perspective: 1000px;
    font-family: sans-serif;
    margin: 0 auto;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border: 1px solid var(--hl-color);
    border-radius: 1rem;
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden;

}

.flip-card-front {
    background: var(--bg-color);
    color: var(--text-color);
    /* background: linear-gradient(120deg, bisque 60%, rgb(255, 231, 222) 88%,
    rgb(255, 211, 195) 40%, rgba(255, 127, 80, 0.603) 48%);
 color: coral; */
}

.flip-card-back {
    background: var(--hl-color);
    color: var(--text-color);
    transform: rotateY(180deg);
}

.action-command,
.action-key {
    font-size: 2em;
    font-weight: 900;
    text-align: center;
    color: var(--font-color);
    margin: 0;
    word-wrap: break-word;
    max-width: 100%;
}



.current-list-name {
    margin-top: 20px;
    font-size: 1.2rem;
    color: var(--font-color);
    text-align: center;
}

span {
    font-size: 1.75rem;
    font-weight: bold;
    color: var(--hl-color);
    margin-bottom: 10px;
}