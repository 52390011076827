/* Container styles */
.history-wrapper {
  width: 100%;
  margin: 0 auto;

  padding: 0 20px;
  box-sizing: border-box;

}

.history-section {
  width: 100%;

  margin: 10px;
  padding: 10px;
  overflow-x: hidden;

}

/* List styles */
.history-list {
  height: 30vh;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 10px;
  row-gap: 10px;
  list-style-type: none;
  margin: 0;
  width: 100%;
  max-width: 1200px;
}



/* Media queries for different screen sizes */
@media screen and (max-width: 1200px) {
  .history-list {
    /* 5 columns */
    grid-template-columns: repeat(5, 1fr);
  }

}

@media screen and (max-width: 992px) {
  .history-list {
    /* 4 columns */
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .history-list {
    /* 3 columns */
    grid-template-columns: repeat(3, 1fr);
    margin: 0 10px;
    gap: 10px;
    width: calc(100% - 20px);
  }
}

@media screen and (max-width: 576px) {
  .history-list {
    /* 2 columns */
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

@media screen and (max-width: 380px) {
  .history-list {
    /* 1 column */
    grid-template-columns: 1fr;
  }
}

/* Add media queries for different screen sizes */
@media screen and (max-width: 768px) {
  .singleInputHistory {
    width: 100px;
    height: 100px;
  }

  .singleInputHistory .shortcut {
    font-size: 16px;
  }

  .singleInputHistory .command {
    font-size: 10px;
  }
}

@media screen and (max-width: 480px) {
  .history-wrapper {
    padding: 0 10px;
  }

  .history-list {
    margin: 0 5px;
    gap: 5px;
    width: calc(100% - 10px);
  }

  .singleInputHistory {
    width: 90px;
    height: 90px;
  }
}

/* History item styles */
.singleInputHistory {
  width: 120px;
  height: 120px;
  background: var(--fg-color);
  border-radius: 15px;
  box-shadow:
    2px 2px 8px color-mix(in srgb, var(--bg-color) 85%, black),
    -1px -1px 4px color-mix(in srgb, var(--bg-color) 90%, white);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.singleInputHistory:hover {
  cursor: pointer;
  box-shadow: 0px 5px 15px color-mix(in srgb, var(--bg-color) 70%, black);
  transform: translateY(-5px);
}

/* Header and content styles */
.singleInputHistory .header {
  height: 40%;
  border-radius: 15px 15px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--font-color);
  padding: 5px;
  background-color: var(--fg-color);
}

.singleInputHistory .content {
  height: 60%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  background-color: var(--hl-color);
}

/* Typography */
.singleInputHistory .shortcut {
  font-family: 'Inconsolata', monospace;
  font-size: 20px;
  font-weight: 600;
  color: var(--font-color);
  margin-bottom: 5px;
  word-break: break-all;
}

.singleInputHistory .command {
  font-family: 'Inconsolata', monospace;
  color: var(--font-color);
  font-size: 12px;
  word-break: break-all;
}

/* Stats section */
.success-rate {
  text-align: center;
  margin-bottom: 1rem;
  font-weight: 500;
}

/* Scrollbar styles */
.history-section::-webkit-scrollbar {
  width: 4px;
  opacity: 0.5;
}

.history-section::-webkit-scrollbar-track {
  background: var(--bg-color);
}

.history-section::-webkit-scrollbar-thumb {
  background-color: var(--hl-color);
  border-radius: 40px;
}

@keyframes correctAnswer {
  0% {
    transform: scale(1) translateY(0);
    box-shadow: 0 0 0 0 transparent;
  }

  25% {
    transform: scale(1.03) translateY(-3px);
    box-shadow: 0 0 8px 1px color-mix(in srgb, var(--font-color) 70%, #00ff00);
    /* Reduced from 15px 2px */
  }

  75% {
    transform: scale(1.03) translateY(-3px);
    box-shadow: 0 0 8px 1px color-mix(in srgb, var(--font-color) 70%, #00ff00);
    /* Reduced from 15px 2px */
  }

  100% {
    transform: scale(1) translateY(0);
    box-shadow: 2px 2px 8px color-mix(in srgb, var(--bg-color) 85%, black),
      -1px -1px 4px color-mix(in srgb, var(--bg-color) 90%, white);
  }
}

@keyframes wrongAnswer {
  0% {
    transform: scale(1) translateY(0);
    box-shadow: 0 0 0 0 transparent;
  }

  25% {
    transform: scale(1.03) translateY(-3px);
    box-shadow: 0 0 8px 1px color-mix(in srgb, var(--font-color) 70%, #ff0000);
    /* Reduced from 15px 2px */
  }

  75% {
    transform: scale(1.03) translateY(-3px);
    box-shadow: 0 0 8px 1px color-mix(in srgb, var(--font-color) 70%, #ff0000);
    /* Reduced from 15px 2px */
  }

  100% {
    transform: scale(1) translateY(0);
    box-shadow: 2px 2px 8px color-mix(in srgb, var(--bg-color) 85%, black),
      -1px -1px 4px color-mix(in srgb, var(--bg-color) 90%, white);
  }
}

.container-history {}

.singleInputHistory.latest {
  animation: scaleDownUp 0.6s ease forwards;
  border: 1px solid white;
}

@keyframes scaleDownUp {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

/* .singleInputHistory.latest:not(.wrong):not(.skipped) {
  animation: correctAnswer 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
} */

/* .singleInputHistory.latest.wrong {
  animation: wrongAnswer 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;

} */