.command-palette-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  list-style-type: none;
  padding: 0;
  justify-items: center;
}

.command-palette {
  position: fixed;
  top: 20px;
  right: 0px;
  z-index: 100;
}

.command-palette button {
  border: none;
  padding: 5px;
  margin: 0;
  background: none;
}

.command-palette button:hover {
  border: none;
  color: var(--hl-color);
}

.theme-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 10px;
}

.theme-option {
  background: none;
  border: none;
  color: var(--font-color);
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.theme-option:hover {
  color: var(--hl-color);
}

.theme-option.selected {
  color: var(--hl-color);
}